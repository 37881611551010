import React, { useState } from "react";
import { Button, Col, FormGroup, Input } from "reactstrap";
import { ContatoProps } from "..";
import api from "../../../../../services/api";
import InputMask from "react-input-mask";

interface ContatoItemProps {
  contato: ContatoProps;
  reload: () => Promise<void>;
  notify: (type: string, message: string) => void;
  removeLocally: (contatoToUpdate: ContatoProps) => void;
  updateLocally: (contatoToUpdate: ContatoProps) => void;
}

export function ContatoItem({
  contato,
  reload,
  notify,
  removeLocally,
  updateLocally,
}: ContatoItemProps) {
  const [ddd, setDdd] = useState(contato?.ddd.toString());
  const [nome, setNome] = useState(contato?.nome);
  const [cargo, setCargo] = useState(contato?.cargo);
  const [numero, setNumero] = useState(contato?.numero.toString());
  const [email, setEmail] = useState(contato?.email);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function valid() {
    const emailRegex = /.+@.+\..+/;
    if (
      nome.length < 2 ||
      cargo.length < 2 ||
      ddd.length < 2 ||
      numero.length < 8 ||
      emailRegex.test(email) === false
    ) {
      notify("danger", "Verifique todos os campos do contato.");
      return false;
    }
    return true;
  }
  async function handleUpdate() {
    const isValid = valid();
    if (!isValid) return;
    try {
      setIsLoading(true);
      if (!contato.id) {
        notify("success", "Contato atualizado com sucesso!");
        updateLocally(contato);
        setIsEditing(false);
        return;
      }
      await api.put(`/common/clientes/contatos/${contato.id}`, {
        cargo,
        nome,
        ddd: +ddd,
        numero: +numero,
        email: email,
      });
      reload();
      setIsEditing(false);
      notify("success", "Contato atualizado com sucesso!");
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRemove() {
    try {
      setIsLoading(true);
      if (!contato.id) {
        notify("success", "Contato removido com sucesso!");
        removeLocally(contato);
        return;
      }
      await api.delete(`/common/clientes/contatos/${contato.id}`);
      reload();
      notify("success", "Contato removido com sucesso!");
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="w-100">
      <div className="d-flex">
        <Col className="w-100 p-1">
          <FormGroup>
            <Input
              disabled={!isEditing}
              placeholder="Cargo"
              className="form-control"
              value={cargo}
              onChange={(e) => setCargo(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col className="w-100 p-1">
          <FormGroup>
            <Input
              disabled={!isEditing}
              placeholder="Nome"
              className="form-control"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </FormGroup>
        </Col>
      </div>
      <div className="w-100 d-flex">
        <Col lg="1" className="p-1">
          <FormGroup>
            <InputMask
              mask="99"
              disabled={!isEditing}
              maskPlaceholder={null}
              className="form-control"
              placeholder="DDD"
              value={ddd}
              onChange={(e) => setDdd(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col className="p-1">
          <FormGroup>
            <InputMask
              mask="999999999"
              disabled={!isEditing}
              maskPlaceholder={null}
              className="form-control"
              placeholder="Número"
              value={numero}
              onChange={(e) => setNumero(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col className="w-100 p-1">
          <FormGroup>
            <Input
              disabled={!isEditing}
              placeholder="Email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* <Col className="p-1" style={{width: "auto"}}>
          
        </Col> */}
        <div>
        <FormGroup className="d-flex align-items-center">
            {isEditing && (
              <Button
                type="button"
                outline
                color="primary"
                size="sm"
                onClick={handleUpdate}
                disabled={isLoading}
              >
                Salvar
              </Button>
            )}
            <Button
              className="btn-sm"
              color="primary"
              onClick={() => setIsEditing(!isEditing)}
            >
              <i
                className={`fas ${isEditing ? "fa-times-circle" : "fa-edit"}`}
              ></i>
            </Button>
            <Button
              className="btn-sm"
              color="danger"
              onClick={handleRemove}
              disabled={isLoading}
            >
              <i className="fas fa-trash"></i>
            </Button>
          </FormGroup>
        </div>
      </div>
    </div>
  );
}
