import React from 'react';
import FormOportunidade from "./Form"

// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader'

import { geolocated } from "react-geolocated";

class New extends React.Component {

    dateOptions() {
        return { year: 'numeric', month: 'long', day: 'numeric' };
    }

    state = {
        oportunidade: {
            "created_at": new Date().toLocaleDateString('pt-br', this.dateOptions)
        }
    }

    render() {

        // console.log('NEW JS')
        // console.log(this.props)
        var coordenadas = this.props.coords
        // console.log({ coordenadas })

        var oportunidadeIdToCrossSelling = this.props.location.state?.oportunidadeToCrossSelling ? this.props.location.state.oportunidadeToCrossSelling : null;
        var tipoCrossSelling = this.props.location.state?.tipoCrossSelling ? this.props.location.state.tipoCrossSelling : null;

        return (
            <>
                <SimpleHeader name="Nova Oportunidade" parentName="Oportunidades" {...this.props} />
                <FormOportunidade {...this.props} oportunidade={this.state.oportunidade} oportunidadeIdToCrossSelling={oportunidadeIdToCrossSelling} tipoCrossSelling={tipoCrossSelling} isNew={true} coordenadas={coordenadas} />
            </>
        )
    }

}

export default geolocated() (New);;
